import React from 'react';
import { styled } from '@mui/system';
import { Paper, Grid, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import waxLogo from '../Logo_WAX_white_secondary.png'; // Adjust the path as needed

const Container = styled('div')({
  padding: '10px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  textAlign: 'center',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const Title = styled('h1')({
  fontSize: '2rem',
  marginBottom: '20px',
});

const FeatureCard = styled(Paper)(({ premium }) => ({
  position: 'relative',
  padding: '20px',
  textAlign: 'left',
  backgroundColor: premium ? '#ff7e04' : '#1e88e5',
  color: '#FFFFFF',
  height: '130px',
  width: '250px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '15px',
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'scale(1.075)',
  },
}));

const FeatureTitle = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
});

const FeatureDescription = styled('div')({
  fontSize: '0.9rem',
  marginTop: '10px',
});

const PremiumIcon = styled(StarIcon)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: '#FFFFFF',
});

const WaxLogo = styled('img')({
  position: 'absolute',
  top: '5px',
  left: '5px',
  width: '96px',
  height: 'auto',
});

const MoreInfo = styled(Link)({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  fontSize: '0.8rem',
  color: '#FFFFFF',
  textDecoration: 'none',
  cursor: 'pointer',
});

const features = [
  { name: 'Loyalty Token', premium: false, web3: false, description: 'Viewers earn customizable loyalty tokens while watching streams', path: '/features/loyalty-token' },
  { name: 'Commands', premium: false, web3: false, description: 'Lots of preset commands and set up custom commands for your stream', path: '/features/commands' },
  { name: 'Timed Messages', premium: false, web3: false, description: 'Set timed messages to display in chat at your own interval', path: '/features/timed-messages' },
  // { name: 'Emotes Overlays', premium: false, web3: false, description: 'Display emotes on screen as they are used in chat', path: '/features/emotes-overlays' },
  // { name: 'Shoutout Overlays', premium: false, web3: false, description: 'Shoutout viewers with a custom message and overlay', path: '/features/shoutout-overlays' },
  // { name: 'Timer Overlay', premium: false, web3: false, description: 'Use commands or events to add or subtract time from a timer', path: '/features/timer-overlay' },
  // { name: 'Follower Goal Overlays', premium: false, web3: false, description: 'Track followers with customizable targets and colours', path: '/features/follower-goal-overlays' },
  // { name: 'Donation Goal Overlays', premium: false, web3: false, description: 'Track donations with customizable targets and colours', path: '/features/donation-goal-overlays' },
  // { name: 'Subscription Goal Overlays', premium: false, web3: false, description: 'Track subscriptions with customizable targets and colours', path: '/features/subscription-goal-overlays' },
  // { name: 'Interactive Chat Games', premium: false, web3: false, description: 'Timed mini games to get the chat super active', path: '/features/interactive-chat-games' },
  // { name: 'End Credits Overlay', premium: true, web3: false, description: 'Show end credits with customizable key stream information', path: '/features/end-credits-overlay' },
  // { name: 'Global Promo Minigames', premium: true, web3: false, description: 'Interactive chat games sponsored by the community', path: '/features/global-promo-minigames' },
  // { name: 'Wallet Creation', premium: true, web3: true, description: 'Create a free WAX wallet for blockchain integration', path: '/features/wallet-creation' },
  // { name: 'Token Creation', premium: true, web3: true, description: 'Easily create and issue your own tokens, the ultimate loyalty token', path: '/features/token-creation' },
  { name: 'Loyalty Token Redemption', premium: true, web3: true, description: 'Redeem loyalty tokens for real blockchain tokens', path: '/features/loyalty-token-redemption' },
  { name: 'Treasure Digs', premium: true, web3: true, description: 'Allow viewers to dig for NFTs from your custom dig pool', path: '/features/treasure-digs' },
  // { name: 'ScurvyStore', premium: true, web3: true, description: 'Set up a store page to sell your items or NFTs for loyalty tokens', path: '/features/scurvy-store' },
  // { name: 'Token Airdrops', premium: true, web3: true, description: 'Customizable airdrops of real blockchain tokens to your community', path: '/features/token-airdrops' },
  // { name: 'Giveaway Overlays', premium: true, web3: false, description: 'Interactive giveaway overlays for on stream prizes', path: '/features/giveaway-overlays' },
];

const Features = () => {
  return (
    <Container>
      <Title>Discover the amazing features of Scurvy Bot</Title>
      <Grid container spacing={3} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item key={index}>
            <FeatureCard premium={feature.premium}>
              {feature.web3 && (
                <Tooltip title="Powered by the WAX blockchain" placement="top">
                  <WaxLogo src={waxLogo} alt="WAX logo" />
                </Tooltip>
              )}
              <FeatureTitle>{feature.name}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
              {feature.premium && (
                <Tooltip title="Premium feature - $4.99/month" placement="top">
                  <PremiumIcon />
                </Tooltip>
              )}
              <MoreInfo to={feature.path}>More info</MoreInfo>
            </FeatureCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
