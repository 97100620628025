import React, { createContext, useState, useEffect } from 'react';
import { SessionKit } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat';

const webRenderer = new WebRenderer();

const sessionKit = new SessionKit({
  appName: 'scurvybot',
  chains: [
    {
      id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
      url: 'https://wax.greymass.com',
    },
  ],
  ui: webRenderer,
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginCloudWallet(),
    new WalletPluginWombat(),
  ]
});

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const restoreSession = async () => {
      try {
        // Attempt to restore the last active session
        const restoredSession = await sessionKit.restore();

        if (restoredSession) {
          console.log('Session restored:', restoredSession);
          setSession(restoredSession);
        } else {
          console.log('No session found to restore.');
        }
      } catch (error) {
        console.error('Failed to restore session:', error);
      }
    };

    restoreSession();
  }, []);

  return (
    <SessionContext.Provider value={{ session, setSession, sessionKit }}>
      {children}
    </SessionContext.Provider>
  );
};
